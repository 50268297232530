<template>
  
<div class="mt-4 bg-white dark:bg-gray-800 overflow-hidden relative lg:flex lg:items-center">
    <div class="w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
        <h2 class="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
            <span class="block">
                Welcome to BIKR
            </span>
        </h2>
        <p class="text-md mt-4 text-gray-600">
            This site is created for the shreddin' community, by shredders like your self.
        </p>
        <p class="text-md mt-4 text-gray-600">
            We, as a community, are always looking for the best bikeparks and getting accurate information about them is not always easy to come by.
        </p>
        <p class="text-md mt-4 text-gray-600">
            That's why we want to call on the community to crowd source relevant information about our favorite bikeparks. The shreddin' community will gather it's own info and help out each other to keep it up to date.
        </p>
        <p class="text-md mt-4 text-gray-600">
            <strong>So create an account today to start helping the community straight away!</strong>
        </p>
        <div class="lg:mt-0 lg:flex-shrink-0">
            <div class="mt-12 inline-flex ">
                <div class="mr-4 shadow rounded-md">
                    <button type="button" class="py-2 px-4  bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                        <router-link :to="{ name: 'Bikeparks'}">Find bikepark</router-link>
                    </button>
                </div>
                <div class="shadow rounded-md">
                    <button type="button" class="py-2 px-4  bg-blue-500 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                        <router-link :to="{ name: 'Signup'}">Create account</router-link>
                    </button>
                </div>
            </div>
        </div>
        <h3 class="text-bold mt-8 text-gray-600 ">
            Reach in to our DM's on Instagram <strong>@clampland</strong> to get in contact
        </h3>
    </div>
    <div class="flex items-center gap-8 p-8 lg:p-24">
        <div clas>
            <img src="@/assets/aremtb.jpg" class="rounded-lg mb-8" alt="Tree" title="Åre Bikepark"/>
            <img src="@/assets/kisamtb.jpg" class="rounded-lg" alt="Tree" title="Kisa MTB Park"/>
        </div>
    </div>
</div>

</template>

<script>
import getCollection from '@/composables/getCollection'
import ListView from '@/components/ListView.vue'
export default {
  name: 'Home',
  setup(){
    const { error, documents, isPending } = getCollection('bikeparks')
    return { error, documents, isPending }
  },
  components: {
    ListView
  }
}
</script>
