<template>
  
  <div v-if="favBikeparks" class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 py-2 my-4 text-center">
    <!-- Activity log -->
    <div class="shadow-lg rounded-xl p-4 bg-white">
        <div class="w-full flex items-center justify-between mb-8">
            <p class="text-gray-800 dark:text-white text-xl font-normal">
                Activity
            </p>
        </div>
        <div class="flex items-start mb-6 rounded justify-between">
            <span class="rounded-full text-white dark:text-gray-800 p-2 bg-yellow-300">
                <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-128-448v320h-1024v-192l192-192 128 128 384-384zm-832-192q-80 0-136-56t-56-136 56-136 136-56 136 56 56 136-56 136-136 56z">
                    </path>
                </svg>
            </span>
            <div class="flex items-center w-full justify-between">
                <div class="flex text-sm flex-col w-full ml-2 items-start justify-between">
                    <p class="text-gray-700 dark:text-white">
                        <span class="font-bold mr-1">
                            Kalle
                        </span>
                        updated Opening Hours
                    </p>
                    <p class="text-gray-300">
                        Jun 10
                    </p>
                </div>
            </div>
        </div>
        <div class="flex items-start mb-6 rounded justify-between">
            <span class="rounded-full text-white dark:text-gray-800 p-2 bg-green-400">
                <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
                    </path>
                </svg>
            </span>
            <div class="flex items-center w-full justify-between">
                <div class="flex text-sm flex-col w-full ml-2 items-start justify-between">
                    <p class="text-gray-700 dark:text-white">
                        <span class="font-bold mr-1">
                            Olle
                        </span>
                        removed trails
                    </p>
                    <p class="text-gray-300">
                        Jun 04
                    </p>
                </div>
            </div>
        </div>
        <div class="flex items-start mb-6 rounded justify-between">
            <span class="rounded-full text-white dark:text-gray-800 p-2 bg-indigo-400">
                <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
                    </path>
                </svg>
            </span>
            <div class="flex items-center w-full justify-between">
                <div class="flex text-sm flex-col w-full ml-2 items-start justify-between">
                    <p class="text-gray-700 dark:text-white">
                        <span class="font-bold mr-1">
                            Hans
                        </span>
                        changed the description
                    </p>
                    <p class="text-gray-300">
                        Jun 01
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- Buttons -->
    <div class="shadow-lg rounded-xl p-4 bg-white">
        <div class="">
            <p class="text-gray-800 dark:text-white text-xl font-normal">
                Fix what's wrong
            </p>
        </div>
        <div class="my-6 justify-items-center ">
            <div class="rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0 mb-4">
                <button @click="handleEdit" class="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 transition duration-150 ease-in-out">
                    Edit
                </button>
            </div>
            
        </div>
        <p class="text-gray-800 dark:text-white text-xl font-normal">
            Magic Admin button
        </p>
        <div class="my-6 justify-items-center ">
            <div class="rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button @click="handleDelete" class="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:bg-red-400 transition duration-150 ease-in-out">
                    Delete
                </button>
            </div>
        </div>
    </div>
    <div v-if="favBikeparks.userFavs.filter((fav) => fav.bikeparkId == props.id).length > 0" class="px-6 py-6 bg-green-700 dark:bg-gray-800 rounded-lg py-12 px-12">
        <div class="">
            <h2 class="text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-3xl sm:leading-9">
                We love that you love this bikepark 
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="h-6 w-6" viewBox="0 0 496 512">
                    <path d="M168 152c-23.8 0-52.7 29.3-56 71.4-.3 3.7 2 7.2 5.6 8.3 3.5 1 7.5-.5 9.3-3.7l9.5-17c7.7-13.7 19.2-21.6 31.5-21.6s23.8 7.9 31.5 21.6l9.5 17c2.1 3.7 6.2 4.7 9.3 3.7 3.6-1.1 5.9-4.5 5.6-8.3-3.1-42.1-32-71.4-55.8-71.4zM248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm56-148c0-19.2-28.8-41.5-71.5-44-3.8-.4-7.4 2.4-8.2 6.2-.9 3.8 1.1 7.7 4.7 9.2l16.9 7.2c13 5.5 20.8 13.5 20.8 21.5s-7.8 16-20.7 21.5l-17 7.2c-5.7 2.4-6 12.2 0 14.8l16.9 7.2c13 5.5 20.8 13.5 20.8 21.5s-7.8 16-20.7 21.5l-17 7.2c-3.6 1.5-5.6 5.4-4.7 9.2.8 3.6 4.1 6.2 7.8 6.2h.5c42.8-2.5 71.5-24.8 71.5-44 0-13-13.4-27.3-35.2-36C290.6 335.3 304 321 304 308zm24-156c-23.8 0-52.7 29.3-56 71.4-.3 3.7 2 7.2 5.6 8.3 3.5 1 7.5-.5 9.3-3.7l9.5-17c7.7-13.7 19.2-21.6 31.5-21.6s23.8 7.9 31.5 21.6l9.5 17c2.1 3.7 6.2 4.7 9.3 3.7 3.6-1.1 5.9-4.5 5.6-8.3-3.1-42.1-32-71.4-55.8-71.4z"/>
                </svg>
            </h2>
            <p class="mt-3 max-w-3xl text-lg leading-6 text-green-200">
                But if you don't want it on your favorite list, just click the button and it's gone!
            </p>
        </div>
        <div class="mt-8 w-full max-w-md">
            <div class="mt-3 rounded-md shadow sm:mt-0 ml-3 flex-shrink-0">
                <button @click="handleDeleteFavorite" class="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-500 hover:bg-green-400 focus:outline-none focus:bg-green-400 transition duration-150 ease-in-out">
                    Adiós mi amor
                </button>
            </div>
        </div>
    </div>
    <div v-else class="px-6 py-6 bg-blue-700 dark:bg-gray-800 rounded-lg py-12 px-12">
        <div class="">
            <h2 class="text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-3xl sm:leading-9">
                Is this one of your favorite bikeparks?
            </h2>
            <p class="mt-3 max-w-3xl text-lg leading-6 text-blue-200">
                Click the button to save it to your favorites, it's an easy way to keep your favorite place up to date
            </p>
        </div>
        <div class="mt-8 w-full max-w-md">
            <div class="mt-3 rounded-md shadow sm:mt-0 ml-3 flex-shrink-0">
                <button @click="handleAddFavorite" class="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 transition duration-150 ease-in-out">
                    Add to my favorites
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import getUser from '@/composables/getUser'
import getDocumentById from '@/composables/getDocumentById'
import useDocumentById from '@/composables/useDocumentById'
import { useRouter } from 'vue-router'

export default {
    props: ['id'],
    setup(props) {
        const { user } = getUser()
        const { error: favError, document: favBikeparks } = getDocumentById('favorites', user.value.uid) // Get user favorites
        const { deleteDoc } = useDocumentById('bikeparks', props.id)
        const { updateDoc } = useDocumentById('favorites', user.value.uid)
        const  router = useRouter()        

        /* FUNCTIONS */
        const handleDelete = async() =>  {
            await deleteDoc()
            router.push({ name: "Home" })
        }

        const handleEdit = async() =>  {
            router.push({ name: "EditBikepark" , params: { id: props.id }})
        }

        const handleAddFavorite = async() =>  {
            const newFavorite = {
                bikeparkId: props.id
            }
            await updateDoc({
                userFavs: [...favBikeparks.value.userFavs, newFavorite]
            })
        }

        const handleDeleteFavorite = async() => {
            const favs = favBikeparks.value.userFavs.filter((fav) => fav.bikeparkId != props.id)
            await updateDoc({ userFavs: favs })
        }

        return { handleDelete, handleEdit, handleAddFavorite, handleDeleteFavorite, user, favBikeparks, props}
    }
}
</script>

<style>

</style>