<template>
  <Navbar />
  <div class="container max-w-7xl min-h-full mx-auto px-8">
    <router-view/>
  </div>
  <Footer />
</template>


<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default({
  components: {
    Navbar,
    Footer
  }
})
</script>
