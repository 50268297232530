import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/auth/Login.vue'
import Signup from '../views/auth/Signup.vue'
import CreateBikepark from '../views/bikeparks/CreateBikepark.vue'
import BikeparkDetails from '../views/bikeparks/BikeparkDetails.vue'
import UserFavorites from '../views/bikeparks/UserFavorites.vue'
import Clampland from '../views/Clampland.vue'
import Changelog from '../views/Changelog.vue'
import NotFound from '../views/NotFound.vue'
import EditBikepark from '../views/bikeparks/EditBikepark.vue'
import Bikeparks from '../views/Bikeparks.vue'
import { projectAuth } from '../firebase/config'
//route guards

const requireAuth = (to, from, next )  => {
  let user = projectAuth.currentUser
  if(!user){
    next({ name: 'Login'})
  } else {
    next()
  }
}

// Fixa title och meta  https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      title: 'BIKR - Community Shreddin'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      title: 'BIKR - Login'
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta:{
      title: 'BIKR - Sign Up'
    }
  },
  {
    path: '/bikeparks',
    name: 'Bikeparks',
    component: Bikeparks,
    meta:{
      title: 'BIKR - Bikeparks'
    }
  },
  {
    path: '/bikepark/create',
    name: 'CreateBikepark',
    component: CreateBikepark,
    beforeEnter: requireAuth,
    meta:{
      title: 'BIKR - Create Bikepark'
    }
  },
  {
    path: '/bikepark/edit/:id',
    name: 'EditBikepark',
    component: EditBikepark,
    beforeEnter: requireAuth,
    props: true,
    meta:{
      title: 'BIKR - Edit Bikepark'
    }
  },
  {
    path: '/bikepark/favorites',
    name: 'UserFavorites',
    component: UserFavorites,
    beforeEnter: requireAuth,
    meta:{
      title: 'BIKR - Favorites'
    }
  },
  {
    path: '/bikepark/:id',
    name: 'BikeparkDetails',
    component: BikeparkDetails,
    props: true,
    meta:{
      title: 'BIKR - Bikepark Details'
    }
  },
  {
    path: '/clampland',
    name: 'Clampland',
    component: Clampland,
    meta:{
      title: 'About clampland'
    }
  },
  {
    path: '/changelog',
    name: 'Changelog',
    component: Changelog,
    meta:{
      title: 'BIKR - Changelog'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
   const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }
  if(!nearestWithMeta) return next();
})

export default router
