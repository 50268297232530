<template>
    <div class=" relative overflow-hidden h-screen">
    
        <div class="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-32 xl:py-40">
            <div class="w-full font-mono flex flex-col items-center relative z-10">
                <h1 class="font-extrabold text-5xl text-center text-black leading-tight mt-4">
                    Rider down!
                </h1>
                <p class="font-extrabold text-8xl my-44 text-black animate-bounce">
                    404
                </p>
                 <button class="px-6 py-2  transition ease-in duration-200 uppercase rounded-full hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none">
                    <router-link :to="{ name: 'Home'}">Get back on track</router-link>
                </button>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>