<template>
    <div class="my-4" v-if="document">
        <div class="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
            <h2 class="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
                <span class="block">
                    {{ document.name }}
                </span>
                <span class="block text-indigo-500">
                    {{ document.catchfrase }}
                </span>
            </h2>
            <p class="text-xl mt-4 max-w-md mx-auto text-gray-400">
                {{ document.description }}
            </p>
        </div>

        <!-- Trails -->
        <h2 class="text-center text-black tracking-widest text-2xl uppercase mb-4">
            Trails
        </h2>
        <section class="bg-gray-400 mb-4 rounded-2xl">
            
            <div class="container mx-auto grid grid-cols-2 gap-8 md:grid-cols-4 py-8 text-center">
                <div>
                    <h5 class="text-5xl font-bold text-white">
                        <span class="inline text-white">
                            {{ document.trails.greens }}
                        </span>
                    </h5>
                    <p class="text-green-600 tracking-wide text-sm font-bold uppercase">
                        green
                    </p>
                </div>
                <div>
                    <h5 class="text-5xl font-bold text-white">
                        <span class="inline text-white">
                            {{ document.trails.blues }}
                        </span>
                    </h5>
                    <p class="text-blue-600 tracking-wide text-sm font-bold uppercase">
                        blue
                    </p>
                </div>
                <div>
                    <h5 class="text-5xl font-bold text-white">
                        <span class="inline text-white">
                            {{ document.trails.reds }}
                        </span>
                    </h5>
                    <p class="text-red-600 tracking-wide text-sm font-bold uppercase">
                        red
                    </p>
                </div>
                <div>
                    <h5 class="text-5xl font-bold text-white">
                        <span class="inline text-white">
                            {{ document.trails.blacks }}
                        </span>
                    </h5>
                    <p class="text-black tracking-wide text-sm font-bold uppercase">
                        black
                    </p>
                </div>
            </div>
        </section>

        <!-- Utilities/FAQ -->
        <h2 class="text-center text-black tracking-widest text-2xl uppercase mb-4">
            Information
        </h2>
        <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 py-2 text-center">
            <div class="shadow-lg rounded-2xl bg-white p-4 w-full">
                <p class="text-gray-800 dark:text-gray-50 text-xl font-medium mb-4">
                    We got
                </p>
                <ul class="text-sm text-gray-600 dark:text-gray-100 mt-6 mb-6">
                    <span v-for="faq in document.faq" :key="faq">
                        <li v-if="faq.available" class="mb-3 flex items-center">
                            <svg class="h-6 w-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                            {{faq.name}}
                        </li>
                    </span>
                </ul>
            </div>
            <div class="shadow-lg rounded-2xl bg-white p-4 w-full">
                <p class="text-gray-800 dark:text-gray-50 text-xl font-medium mb-4">
                    We don't do
                </p>
                <ul class="text-sm text-gray-600 dark:text-gray-100 w-full mt-6 mb-6">
                    <span v-for="faq in document.faq" :key="faq">
                        <li v-if="!faq.available" class="mb-3 flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" class="h-6 w-6 mr-2" fill="red" viewBox="0 0 1792 1792">
                                <path d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                            {{faq.name}}
                        </li>
                    </span>
                </ul>
            </div>
            <div class="shadow-lg rounded-2xl bg-white p-4 w-full">
                <p class="text-gray-800 dark:text-gray-50 text-xl font-medium mb-4">
                    Contacts
                </p>
                <ul class="text-sm text-gray-600 dark:text-gray-100 w-full mt-6 mb-6">
                    <!-- Phone -->
                    <li v-if="document.info.phone" class="mb-3 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="h-6 w-6 mr-2 bi bi-telephone" viewBox="0 0 16 16">
                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                        </svg>
                        <a :href="'tel:'+document.info.phone">
                            {{document.info.phone}}
                        </a>
                    </li>
                    <!-- E-mail -->
                    <li v-if="document.info.email" class="mb-3 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="h-6 w-6 mr-2 bi bi-envelope" viewBox="0 0 16 16">
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                        </svg>
                        <a :href="'mailto:'+document.info.email"  target="_blank">
                            {{ document.info.email }}
                        </a>
                    </li>
                    <!-- Google Maps -->
                    <li v-if="document.info.googleMaps" class="mb-3 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2 bi bi-geo-alt" viewBox="0 0 16 16">
                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                        </svg>
                        <a :href="document.info.googleMaps"  target="_blank">
                            Google Maps
                        </a>
                    </li>
                    <p class="text-gray-800 dark:text-gray-50 text-xl font-medium mb-4">
                        Socials
                    </p>
                    <!-- Instagram -->
                    <li v-if="document.info.instagram" class="mb-3 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2 bi bi-instagram" viewBox="0 0 16 16">
                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                        </svg>
                        <a :href="document.info.instagram"  target="_blank">
                            Instagram
                        </a>
                    </li>
                    <!-- Facebook -->
                    <li v-if="document.info.facebook" class="mb-3 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2 bi bi-facebook" viewBox="0 0 16 16">
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                        </svg>
                        <a :href="document.info.facebook"  target="_blank">
                            Facebook
                        </a>
                    </li>
                    
                    <!-- Spotify -->
                    <li v-if="document.info.spotify" class="mb-3 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 496 512">
                            <path d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm100.7 364.9c-4.2 0-6.8-1.3-10.7-3.6-62.4-37.6-135-39.2-206.7-24.5-3.9 1-9 2.6-11.9 2.6-9.7 0-15.8-7.7-15.8-15.8 0-10.3 6.1-15.2 13.6-16.8 81.9-18.1 165.6-16.5 237 26.2 6.1 3.9 9.7 7.4 9.7 16.5s-7.1 15.4-15.2 15.4zm26.9-65.6c-5.2 0-8.7-2.3-12.3-4.2-62.5-37-155.7-51.9-238.6-29.4-4.8 1.3-7.4 2.6-11.9 2.6-10.7 0-19.4-8.7-19.4-19.4s5.2-17.8 15.5-20.7c27.8-7.8 56.2-13.6 97.8-13.6 64.9 0 127.6 16.1 177 45.5 8.1 4.8 11.3 11 11.3 19.7-.1 10.8-8.5 19.5-19.4 19.5zm31-76.2c-5.2 0-8.4-1.3-12.9-3.9-71.2-42.5-198.5-52.7-280.9-29.7-3.6 1-8.1 2.6-12.9 2.6-13.2 0-23.3-10.3-23.3-23.6 0-13.6 8.4-21.3 17.4-23.9 35.2-10.3 74.6-15.2 117.5-15.2 73 0 149.5 15.2 205.4 47.8 7.8 4.5 12.9 10.7 12.9 22.6 0 13.6-11 23.3-23.2 23.3z"/>
                        </svg>
                        <a :href="document.info.spotify"  target="_blank">
                            Spotify playlist
                        </a>
                    </li>
                    <!-- Trailforks -->
                    <li v-if="document.info.trailforks" class="mb-3 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 64 64">
                            <path d="M15.228,42.046c0.353-1.322,0.7-4.521,0.941-6.755c0.681-6.292,1.011-8.224,1.883-8.902   c0.267-0.207,0.605-0.292,0.943-0.241c0.958,0.145,1.806,1.251,4.177,4.639c1.403,2.003,4.289,6.125,5.387,6.351   c0.048-0.074,0.135-0.247,0.215-0.604l0.279-1.262c3.526-16.004,5.472-21.534,7.708-21.915c0.411-0.071,0.975,0.147,1.84,0.992   c3.231,3.155,15.739,11.791,20.686,13.093L32,0.273L2.56,29.713C7.808,35.322,13.302,40.779,15.228,42.046z"/>
                            <path d="M61.317,29.465c-0.164,0.045-0.34,0.082-0.546,0.096c-4.504,0.292-20.584-10.124-23.82-14.079   c-0.877,1.502-3.276,8.109-5.945,20.22l-0.28,1.265c-0.278,1.251-0.861,1.972-1.733,2.146c-1.996,0.393-4.147-2.449-7.458-7.178   c-0.779-1.112-1.77-2.527-2.431-3.306c-0.363,1.489-0.705,4.645-0.946,6.878c-0.665,6.144-0.964,7.898-1.8,8.537   c-0.159,0.122-0.335,0.256-0.639,0.256c-1.134,0-4.06-1.866-14.577-13.168l-1.005,1.005L32,64l31.932-31.932L61.317,29.465z"/>
                        </svg>
                        <a :href="document.info.trailforks"  target="_blank">
                            Trailforks
                        </a>
                    </li>
                </ul>
            </div>
            <div class="shadow-lg rounded-2xl bg-white p-4 w-full">
                <p class="text-gray-800 dark:text-gray-50 text-xl font-medium mb-4">
                    Opening Hours
                </p>
                <ul class="text-sm text-gray-600 dark:text-gray-100 w-full mt-6 mb-6">
                    <li class="mb-3 flex items-center">
                        <span class="font-semibold">Monday &nbsp;</span>
                        <div v-if="document.openingHours.monday.opens != '' && document.openingHours.monday.closes != ''" class="inline-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 16 16">
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l1.5 1.5a.5.5 0 0 1-.708.708L8.5 2.707V4.5a.5.5 0 0 1-1 0V2.707l-.646.647a.5.5 0 1 1-.708-.708l1.5-1.5zM2.343 4.343a.5.5 0 0 1 .707 0l1.414 1.414a.5.5 0 0 1-.707.707L2.343 5.05a.5.5 0 0 1 0-.707zm11.314 0a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zM8 7a3 3 0 0 1 2.599 4.5H5.4A3 3 0 0 1 8 7zm3.71 4.5a4 4 0 1 0-7.418 0H.499a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-3.79zM0 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 10zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <span class="mr-2">{{ document.openingHours.monday.opens }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 16 16">
                                <path d="M7.646 4.854a.5.5 0 0 0 .708 0l1.5-1.5a.5.5 0 0 0-.708-.708l-.646.647V1.5a.5.5 0 0 0-1 0v1.793l-.646-.647a.5.5 0 1 0-.708.708l1.5 1.5zm-5.303-.51a.5.5 0 0 1 .707 0l1.414 1.413a.5.5 0 0 1-.707.707L2.343 5.05a.5.5 0 0 1 0-.707zm11.314 0a.5.5 0 0 1 0 .706l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zM8 7a3 3 0 0 1 2.599 4.5H5.4A3 3 0 0 1 8 7zm3.71 4.5a4 4 0 1 0-7.418 0H.499a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-3.79zM0 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 10zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <span>{{ document.openingHours.monday.closes }}</span>
                        </div>
                        <div v-else>
                            <span>Closed</span>
                        </div>
                    </li>
                    <li class="mb-3 flex items-center">
                        <span class="font-semibold">Thuesday &nbsp;</span>
                        <div v-if="document.openingHours.thuesday.opens != '' && document.openingHours.thuesday.closes != ''" class="inline-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 16 16">
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l1.5 1.5a.5.5 0 0 1-.708.708L8.5 2.707V4.5a.5.5 0 0 1-1 0V2.707l-.646.647a.5.5 0 1 1-.708-.708l1.5-1.5zM2.343 4.343a.5.5 0 0 1 .707 0l1.414 1.414a.5.5 0 0 1-.707.707L2.343 5.05a.5.5 0 0 1 0-.707zm11.314 0a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zM8 7a3 3 0 0 1 2.599 4.5H5.4A3 3 0 0 1 8 7zm3.71 4.5a4 4 0 1 0-7.418 0H.499a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-3.79zM0 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 10zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <span class="mr-2">{{ document.openingHours.thuesday.opens }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 16 16">
                                <path d="M7.646 4.854a.5.5 0 0 0 .708 0l1.5-1.5a.5.5 0 0 0-.708-.708l-.646.647V1.5a.5.5 0 0 0-1 0v1.793l-.646-.647a.5.5 0 1 0-.708.708l1.5 1.5zm-5.303-.51a.5.5 0 0 1 .707 0l1.414 1.413a.5.5 0 0 1-.707.707L2.343 5.05a.5.5 0 0 1 0-.707zm11.314 0a.5.5 0 0 1 0 .706l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zM8 7a3 3 0 0 1 2.599 4.5H5.4A3 3 0 0 1 8 7zm3.71 4.5a4 4 0 1 0-7.418 0H.499a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-3.79zM0 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 10zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <span>{{ document.openingHours.thuesday.closes }}</span>
                        </div>
                        <div v-else>
                            <span>Closed</span>
                        </div>
                    </li>
                    <li class="mb-3 flex items-center">
                        <span class="font-semibold">Wednesday &nbsp;</span>
                        <div v-if="document.openingHours.wednsday.opens != '' && document.openingHours.wednsday.closes != ''" class="inline-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 16 16">
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l1.5 1.5a.5.5 0 0 1-.708.708L8.5 2.707V4.5a.5.5 0 0 1-1 0V2.707l-.646.647a.5.5 0 1 1-.708-.708l1.5-1.5zM2.343 4.343a.5.5 0 0 1 .707 0l1.414 1.414a.5.5 0 0 1-.707.707L2.343 5.05a.5.5 0 0 1 0-.707zm11.314 0a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zM8 7a3 3 0 0 1 2.599 4.5H5.4A3 3 0 0 1 8 7zm3.71 4.5a4 4 0 1 0-7.418 0H.499a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-3.79zM0 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 10zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <span class="mr-2">{{ document.openingHours.wednsday.opens }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 16 16">
                                <path d="M7.646 4.854a.5.5 0 0 0 .708 0l1.5-1.5a.5.5 0 0 0-.708-.708l-.646.647V1.5a.5.5 0 0 0-1 0v1.793l-.646-.647a.5.5 0 1 0-.708.708l1.5 1.5zm-5.303-.51a.5.5 0 0 1 .707 0l1.414 1.413a.5.5 0 0 1-.707.707L2.343 5.05a.5.5 0 0 1 0-.707zm11.314 0a.5.5 0 0 1 0 .706l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zM8 7a3 3 0 0 1 2.599 4.5H5.4A3 3 0 0 1 8 7zm3.71 4.5a4 4 0 1 0-7.418 0H.499a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-3.79zM0 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 10zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <span>{{ document.openingHours.wednsday.closes }}</span>
                        </div>
                        <div v-else>
                            <span>Closed</span>
                        </div>
                    </li>
                    <li class="mb-3 flex items-center">
                        <span class="font-semibold">Thursday &nbsp;</span>
                        <div v-if="document.openingHours.thursday.opens != '' && document.openingHours.thursday.closes != ''" class="inline-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 16 16">
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l1.5 1.5a.5.5 0 0 1-.708.708L8.5 2.707V4.5a.5.5 0 0 1-1 0V2.707l-.646.647a.5.5 0 1 1-.708-.708l1.5-1.5zM2.343 4.343a.5.5 0 0 1 .707 0l1.414 1.414a.5.5 0 0 1-.707.707L2.343 5.05a.5.5 0 0 1 0-.707zm11.314 0a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zM8 7a3 3 0 0 1 2.599 4.5H5.4A3 3 0 0 1 8 7zm3.71 4.5a4 4 0 1 0-7.418 0H.499a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-3.79zM0 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 10zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <span class="mr-2">{{ document.openingHours.thursday.opens }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 16 16">
                                <path d="M7.646 4.854a.5.5 0 0 0 .708 0l1.5-1.5a.5.5 0 0 0-.708-.708l-.646.647V1.5a.5.5 0 0 0-1 0v1.793l-.646-.647a.5.5 0 1 0-.708.708l1.5 1.5zm-5.303-.51a.5.5 0 0 1 .707 0l1.414 1.413a.5.5 0 0 1-.707.707L2.343 5.05a.5.5 0 0 1 0-.707zm11.314 0a.5.5 0 0 1 0 .706l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zM8 7a3 3 0 0 1 2.599 4.5H5.4A3 3 0 0 1 8 7zm3.71 4.5a4 4 0 1 0-7.418 0H.499a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-3.79zM0 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 10zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <span>{{ document.openingHours.thursday.closes }}</span>
                        </div>
                        <div v-else>
                            <span>Closed</span>
                        </div>
                    </li>
                    <li class="mb-3 flex items-center">
                        <span class="font-semibold">Friday &nbsp;</span>
                        <div v-if="document.openingHours.friday.opens != '' && document.openingHours.friday.closes != ''" class="inline-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 16 16">
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l1.5 1.5a.5.5 0 0 1-.708.708L8.5 2.707V4.5a.5.5 0 0 1-1 0V2.707l-.646.647a.5.5 0 1 1-.708-.708l1.5-1.5zM2.343 4.343a.5.5 0 0 1 .707 0l1.414 1.414a.5.5 0 0 1-.707.707L2.343 5.05a.5.5 0 0 1 0-.707zm11.314 0a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zM8 7a3 3 0 0 1 2.599 4.5H5.4A3 3 0 0 1 8 7zm3.71 4.5a4 4 0 1 0-7.418 0H.499a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-3.79zM0 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 10zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <span class="mr-2">{{ document.openingHours.friday.opens }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 16 16">
                                <path d="M7.646 4.854a.5.5 0 0 0 .708 0l1.5-1.5a.5.5 0 0 0-.708-.708l-.646.647V1.5a.5.5 0 0 0-1 0v1.793l-.646-.647a.5.5 0 1 0-.708.708l1.5 1.5zm-5.303-.51a.5.5 0 0 1 .707 0l1.414 1.413a.5.5 0 0 1-.707.707L2.343 5.05a.5.5 0 0 1 0-.707zm11.314 0a.5.5 0 0 1 0 .706l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zM8 7a3 3 0 0 1 2.599 4.5H5.4A3 3 0 0 1 8 7zm3.71 4.5a4 4 0 1 0-7.418 0H.499a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-3.79zM0 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 10zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <span>{{ document.openingHours.friday.closes }}</span>
                        </div>
                        <div v-else>
                            <span>Closed</span>
                        </div>
                    </li>
                    <li class="mb-3 flex items-center">
                        <span class="font-semibold">Saturday &nbsp;</span>
                        <div v-if="document.openingHours.saturday.opens != '' && document.openingHours.saturday.closes != ''" class="inline-flex" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 16 16">
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l1.5 1.5a.5.5 0 0 1-.708.708L8.5 2.707V4.5a.5.5 0 0 1-1 0V2.707l-.646.647a.5.5 0 1 1-.708-.708l1.5-1.5zM2.343 4.343a.5.5 0 0 1 .707 0l1.414 1.414a.5.5 0 0 1-.707.707L2.343 5.05a.5.5 0 0 1 0-.707zm11.314 0a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zM8 7a3 3 0 0 1 2.599 4.5H5.4A3 3 0 0 1 8 7zm3.71 4.5a4 4 0 1 0-7.418 0H.499a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-3.79zM0 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 10zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <span class="mr-2">{{ document.openingHours.saturday.opens }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 16 16">
                                <path d="M7.646 4.854a.5.5 0 0 0 .708 0l1.5-1.5a.5.5 0 0 0-.708-.708l-.646.647V1.5a.5.5 0 0 0-1 0v1.793l-.646-.647a.5.5 0 1 0-.708.708l1.5 1.5zm-5.303-.51a.5.5 0 0 1 .707 0l1.414 1.413a.5.5 0 0 1-.707.707L2.343 5.05a.5.5 0 0 1 0-.707zm11.314 0a.5.5 0 0 1 0 .706l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zM8 7a3 3 0 0 1 2.599 4.5H5.4A3 3 0 0 1 8 7zm3.71 4.5a4 4 0 1 0-7.418 0H.499a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-3.79zM0 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 10zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <span>{{ document.openingHours.saturday.closes }}</span>
                        </div>
                        <div v-else>
                            <span>Closed</span>
                        </div>
                    </li>
                    <li class="mb-3 flex items-center">
                        <span class="font-semibold">Sunday &nbsp;</span>
                        <div v-if="document.openingHours.sunday.opens != '' && document.openingHours.sunday.closes != ''" class="inline-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 16 16">
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l1.5 1.5a.5.5 0 0 1-.708.708L8.5 2.707V4.5a.5.5 0 0 1-1 0V2.707l-.646.647a.5.5 0 1 1-.708-.708l1.5-1.5zM2.343 4.343a.5.5 0 0 1 .707 0l1.414 1.414a.5.5 0 0 1-.707.707L2.343 5.05a.5.5 0 0 1 0-.707zm11.314 0a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zM8 7a3 3 0 0 1 2.599 4.5H5.4A3 3 0 0 1 8 7zm3.71 4.5a4 4 0 1 0-7.418 0H.499a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-3.79zM0 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 10zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <span class="mr-2">{{ document.openingHours.sunday.opens }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="h-6 w-6 mr-2" viewBox="0 0 16 16">
                                <path d="M7.646 4.854a.5.5 0 0 0 .708 0l1.5-1.5a.5.5 0 0 0-.708-.708l-.646.647V1.5a.5.5 0 0 0-1 0v1.793l-.646-.647a.5.5 0 1 0-.708.708l1.5 1.5zm-5.303-.51a.5.5 0 0 1 .707 0l1.414 1.413a.5.5 0 0 1-.707.707L2.343 5.05a.5.5 0 0 1 0-.707zm11.314 0a.5.5 0 0 1 0 .706l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zM8 7a3 3 0 0 1 2.599 4.5H5.4A3 3 0 0 1 8 7zm3.71 4.5a4 4 0 1 0-7.418 0H.499a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-3.79zM0 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 10zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <span>{{ document.openingHours.sunday.closes }}</span>
                        </div>
                        <div v-else>
                            <span>Closed</span>
                        </div>
                    </li>
                    <li v-if="document.openingHours.comments && document.openingHours.comments.length > 0">
                        <span class="font-semibold">Comments</span>
                        <br/>
                        <p >{{ document.openingHours.comments }} </p>
                    </li>
                </ul>
            </div>
        </div>
        <!-- Google Maps -->
        <div class="mt-4">
            <iframe 
                :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyAj_uUrcYET_eQnE3B5AnyD64cpDhvNGW8&q=${document.name}`"
                width="100%" 
                height="450px" 
                style="border:1px;" 
                allowfullscreen="" 
                loading="lazy"></iframe>
        </div>
        <div v-if="user">
            <AuthedBikeparkDetails :id="document.id"/>
        </div>
        
    </div>
</template>

<script>
import getDocumentById from '@/composables/getDocumentById'
import getUser from '@/composables/getUser'
import AuthedBikeparkDetails from '@/components/AuthedBikeparkDetails.vue'


export default {
    props: ['id'],
    components: {
        AuthedBikeparkDetails,
    },
    setup(props){
        const { user } = getUser()
        const { error, document } = getDocumentById('bikeparks', props.id) // get bikepark with ID
        
        return { error, document, user }
    }
}
</script>

<style>
</style>

