<template>
    <div class="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
        <h2 class="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
            <span class="block">
                bikr.se changelog
            </span>
        </h2>
        <p class="text-xl mt-4 max-w-md mx-auto text-gray-400">
           Details and informations about what goes on behind the scene.
        </p>
    </div>
    <div class="container mx-auto grid grid-cols-1 bg-white max-w-2xl shadow overflow-hidden sm:rounded-lg">
        <div class="border-t border-gray-200">
            <dl>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                        2021-08-??
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <ul>
                            <li>- Next best  thing</li>
                        </ul>
                    </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                        2021-08-01
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <ul>
                            <li>- Added Google Maps Embedded component in Bikepark Details</li>
                            <li>- Added changelog page</li>
                        </ul>
                    </dd>
                </div>
            </dl>
        </div>
    </div>

</template>

<script>
export default {

}
</script>

<style>

</style>