<template>
    <div class="relative">
        <label class="block text-gray-700">
            {{props.label}}
        </label>
        <input type="text" 
            class=" rounded-lg border-transparent appearance-none border w-full border-gray-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
            :value="modelValue" 
            @input="$emit('update:modelValue', $event.target.value)" 
            :placeholder="props.placeholder" 
            />

            <span>{{props.model}}</span>
    </div>
</template>

<script>
export default {
    props: [
        'label',
        'placeholder',
        'modelValue'
    ],
    setup(props){

        return { props }   
    }
}
</script>

<style>

</style>