import firebase from "firebase";
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

// Uppdatera till v9 soon
// import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
// import { getFirestore } from 'firebase/firestore';
// import { getStorage } from 'firebase/storage'

const firebaseConfig = {
    //apiKey: "AIzaSyDOE0To6H4MvMs2I2w00FoRDXd3zxb6bEk",
    apiKey:"AIzaSyAj_uUrcYET_eQnE3B5AnyD64cpDhvNGW8",
    authDomain: "bikr-se.firebaseapp.com",
    projectId: "bikr-se",
    storageBucket: "bikr-se.appspot.com",
    messagingSenderId: "1018123839764",
    appId: "1:1018123839764:web:391f9697b04a5914effa01"
};

// Init
firebase.initializeApp(firebaseConfig)

// Init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()
// timestamp
const  timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectFirestore, projectAuth, projectStorage, timestamp }
