<template>
<div class="flex items-center justify-center my-12">
    <div class="max-w-full px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
        <div class="self-center mb-2 text-xl font-light text-gray-800 sm:text-2xl dark:text-white">
            Add a new Bikepark
        </div>
    </div>
</div>
<form @submit.prevent="handleSubmit">
    <!-- The Basics -->
    <div class="flex items-center justify-center my-12">
        <div class="max-w-full px-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
            <div class="p-6">
                <div class="self-center mb-2 text-xl font-light text-gray-800 sm:text-2xl dark:text-white">
                    The Basics
                </div>
                    <!-- Name -->
                <div class="w-full mb-2">
                    <TextInputField v-model="bikepark.name" :label="'Name'" :placeholder="'Dirt Surf Park'"/>
                </div>
                <div class="w-full mb-2">
                    <TextInputField v-model="bikepark.description" :label="'Description'" :placeholder="'This park is the top 5 park in the world...'"/>
                </div>
                <div class="w-full mb-2">
                    <TextInputField v-model="bikepark.catchfrase" :label="'Catch Phrase!'" :placeholder="'Super shreddin\''"/>
                </div>
                    <!-- Phone & Email -->
                <div class="flex gap-4 mb-2">
                    <TextInputField v-model="bikepark.info.phone" :label="'Phone'" :placeholder="'+46 1234 1234'"/>
                    <TextInputField v-model="bikepark.info.email" :label="'E-mail'" :placeholder="'info@bikr.se'"/>
                </div>
                    <!-- Country & Region -->
                <div class="flex gap-4 mb-2">
                    <label class="text-gray-700" for="animals">
                        Country
                        <select @change="changeCountry($event)" id="animals" class="block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500" name="countries">
                            <option value="" selected disabled>
                                Select a country
                            </option>
                            <option value="Sweden">
                                Sweden
                            </option>
                            <option value="Norway">
                                Norway
                            </option>
                            <option value="Denmark">
                                Denmark
                            </option>
                            <option value="Finland">
                                Finland
                            </option>
                        </select>
                    </label>
                    <TextInputField v-model="bikepark.info.region" :label="'Region'" :placeholder="'Skåne'"/>
                </div>
                    <!-- City & Google Maps -->
                <div class="flex gap-4 mb-2">
                    <TextInputField v-model="bikepark.info.city" :label="'City'" :placeholder="'Vånga'"/>
                    <TextInputField v-model="bikepark.info.googleMaps" :label="'Google Maps'" :placeholder="'https://www.google.se/maps/place/KISA+MTB+PARK/@57.9700617,15.6159865,17z/data=!3m1!4b1!4m5!3m4!1s0x4659a2c6b45b2e01:0xd7014cc59ee355c4!8m2!3d57.9700589!4d15.6181752'"/>
                </div>            
            </div> 
        </div>
    </div>
    <!-- The Socials -->
    <div class="flex items-center justify-center my-12">
        <div class="max-w-full px-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
            <div class="p-6">
                <div class="self-center mb-2 text-xl font-light text-gray-800 sm:text-2xl dark:text-white">
                    The Socials
                </div>
                    <!-- Instagram & Facebook -->
                <div class="flex gap-4 mb-2">
                    <TextInputField v-model="bikepark.info.instagram" :label="'Instagram'" :placeholder="'https://instagram.com/clampland'"/>
                    <TextInputField v-model="bikepark.info.facebook" :label="'Facebook'" :placeholder="'https://facebook.com/clampland'"/>
                </div>
                <!-- Myspace & SoundCloud-->
                 <div class="flex gap-4 mb-2">
                    <TextInputField v-model="bikepark.info.trailforks" :label="'Trailforks'" :placeholder="'https://www.trailforks.com/region/flottsbro-bikepark-18446/'"/>
                    <TextInputField v-model="bikepark.info.spotify" :label="'Spotify'" :placeholder="'https://open.spotify.com/playlist/1xNWydoMmA8210KeHU948w?si=gxQGCd8-ST2yRndr1Jnqbg&nd=1'"/>
                </div>
            </div> 
        </div>
    </div>

<!-- The FAQs -->
    <div class="flex items-center justify-center my-12">
        <div class=" max-w-full px-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
            <div class="p-6 ">
                <div class="self-center mb-5 text-xl font-light text-gray-800 sm:text-2xl dark:text-white">
                    The Frequently Asked Questions <br/>

                    <small class="text-sm">This bike park has:</small>
                </div>
                <div class="grid gap-1 grid-cols-2">
                    <div v-for="faq in bikepark.faq" :key="faq">
                        <div class="relative inline-block w-10 mr-2 align-middle select-none">
                            <input v-model="faq.available" :checked="faq.available.value" type="checkbox" class="checked:bg-blue-500 outline-none focus:outline-none right-4 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
                            <label class="block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
                            </label>
                        </div>
                        <span class="text-gray-400 font-medium">
                            {{faq.name}}
                        </span>
                    </div>
              </div>
            </div> 
        </div>
    </div>
    <!-- The Trails -->
    <div class="flex items-center justify-center my-12">
        <div class="max-w-full px-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
            <div class="p-6">
                <div class="self-center mb-5 text-xl font-light text-gray-800 sm:text-2xl dark:text-white">
                    The Trails
                </div>
                 <div class="flex gap-4 mb-2">
                    <div class="relative">
                        <label class="text-gray-700">
                            Green Trails
                        </label>
                        <input type="number" 
                            class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                            v-model="bikepark.trails.greens"
                            />
                    </div>
                    <div class="relative">
                        <label class="text-gray-700">
                            Blue Trails
                        </label>
                        <input type="number" 
                            class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                            v-model="bikepark.trails.blues"
                            />
                    </div>
                 </div>
                <div class="flex gap-4 mb-2">
                    <div class="relative">
                        <label class="text-gray-700">
                            Red Trails
                        </label>
                        <input type="number" 
                            class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                            v-model="bikepark.trails.reds"
                            />
                    </div>
                    <div class="relative">
                        <label class="text-gray-700">
                            Black Trails
                        </label>
                        <input type="number" 
                            class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                            v-model="bikepark.trails.blacks"
                            />
                    </div>
                </div>
                <div class="flex gap-4 mb-4">
                    <div class="relative">
                        <label class="text-gray-700">
                            Jump Lines
                        </label>
                        <input type="number" 
                            class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                            v-model="bikepark.trails.jumplines"
                            />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- The Opening Hours -->
    <div class="flex items-center justify-center my-12">
        <div class="max-w-full px-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
            <div class="p-6">
                <div class="self-center mb-2 text-xl font-light text-gray-800 sm:text-2xl dark:text-white">
                    The Opening Hours
                </div>
                    <!-- Monday -->
                <div class="flex gap-4 mb-2">
                    <div class="self-center mb-2 text-sm font-light text-gray-800 sm:text-2xl dark:text-white">
                        Monday
                    </div>
                    <label class="text-gray-700"> Open
                        <input v-model='bikepark.openingHours.monday.opens' type="time" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"/>
                    </label>
                    <label class="text-gray-700"> Close
                        <input v-model='bikepark.openingHours.monday.closes' type="time" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"/>
                    </label>
                </div>
                <div class="flex gap-4 mb-2">
                    <div class="self-center mb-2 text-sm font-light text-gray-800 sm:text-2xl dark:text-white">
                        Thuesday
                    </div>
                    <label class="text-gray-700"> Open
                        <input v-model='bikepark.openingHours.thuesday.opens' type="time" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"/>
                    </label>
                    <label class="text-gray-700"> Close
                        <input v-model='bikepark.openingHours.thuesday.closes' type="time" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"/>
                    </label>
                </div>
                <div class="flex gap-4 mb-2">
                    <div class="self-center mb-2 text-sm font-light text-gray-800 sm:text-2xl dark:text-white">
                        Wednesday
                    </div>
                    <label class="text-gray-700"> Open
                        <input v-model='bikepark.openingHours.wednsday.opens' type="time" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"/>
                    </label>
                    <label class="text-gray-700"> Close
                        <input v-model='bikepark.openingHours.wednsday.closes' type="time" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"/>
                    </label>
                </div>
                <div class="flex gap-4 mb-2">
                    <div class="self-center mb-2 text-sm font-light text-gray-800 sm:text-2xl dark:text-white">
                        Thursday
                    </div>
                    <label class="text-gray-700"> Open
                        <input v-model='bikepark.openingHours.thursday.opens' type="time" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"/>
                    </label>
                    <label class="text-gray-700"> Close
                        <input v-model='bikepark.openingHours.thursday.closes' type="time" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"/>
                    </label>
                </div>
                <div class="flex gap-4 mb-2">
                    <div class="self-center mb-2 text-sm font-light text-gray-800 sm:text-2xl dark:text-white">
                        Friday
                    </div>
                    <label class="text-gray-700"> Open
                        <input v-model='bikepark.openingHours.friday.opens' type="time" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"/>
                    </label>
                    <label class="text-gray-700"> Close
                        <input v-model='bikepark.openingHours.friday.closes' type="time" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"/>
                    </label>
                </div>
                <div class="flex gap-4 mb-2">
                    <div class="self-center mb-2 text-sm font-light text-gray-800 sm:text-2xl dark:text-white">
                        Saturday
                    </div>
                    <label class="text-gray-700"> Open
                        <input v-model='bikepark.openingHours.saturday.opens' type="time" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"/>
                    </label>
                    <label class="text-gray-700"> Close
                        <input v-model='bikepark.openingHours.saturday.closes' type="time" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"/>
                    </label>
                </div>
                <div class="flex gap-4 mb-2">
                    <div class="self-center mb-2 text-sm font-light text-gray-800 sm:text-2xl dark:text-white">
                        Sunday
                    </div>
                    <label class="text-gray-700"> Open
                        <input v-model='bikepark.openingHours.sunday.opens' type="time" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"/>
                    </label>
                    <label class="text-gray-700"> Close
                        <input v-model='bikepark.openingHours.sunday.closes' type="time" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"/>
                    </label>
                </div>
                <div class="flex gap-4 mb-2">
                    <label class="text-gray-700" for="name"> Comments
                        <textarea v-model="bikepark.openingHours.comments" class="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" id="comment" placeholder="Ex. 'Only open week 25, 27, 29' or 'Only open once a month'" name="comment" rows="5" cols="40">
                        </textarea>
                    </label>
                </div>
            </div> 
        </div>
    </div>

    <!-- Submit button -->
    <div class="flex items-center justify-center my-12">
        <div class="max-w-full px-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
            <div class="p-6 mt-8">
                <div class="self-center mb-2 text-xl font-light text-gray-800 sm:text-2xl dark:text-white">
                    Going in for the save!
                </div>
                <div class="flex w-full my-4">
                    <button class="py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg " v-if="!isPending">Save Bikepark</button>
                    <button class="py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg " v-else disabled>Saving...</button> 
                </div>
            </div>
        </div>
    </div>
</form>

</template>

<script>
import { ref } from '@vue/reactivity'
import useStorage from '@/composables/useStorage'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import { useRouter } from 'vue-router'
import TextInputField from '@/components/TextInputField.vue'

export default {
    components:{
        TextInputField
    },
    setup(){
        const { url, filePath, uploadImage } = useStorage()
        const { error, addDoc } = useCollection('bikeparks')
        const bannerError = ref(null)
        const { user } = getUser()
        const isPending =  ref(false)
        const router = useRouter()


        const bikepark = {
            name: '',
            description: '',
            catchfrase: '',
            coverUrl: '',
            userId: user.value.uid,
            createdByUser: user.value.displayName,
            updatedByUser: user.value.displayName,
            createdAt: timestamp(),
            updatedAt: timestamp(),
            info: {
                instagram: '',
                facebook: '',
                trailforks: '',
                spotify: '',
                phone: '',
                email: '',
                country: '',
                region: '',
                city: '',
                googleMaps: ''
            },
            faq: {
                freeParking: {
                    name: "Free Parking",
                    available: false
                },
                paidParking: {
                    name: "Paid Parking",
                    available: false
                },
                vanCampingAllowed: {
                    name: "Van Camping",
                    available: false
                },
                tentCampingAllowed: {
                    name: "Tent Camping",
                    available: false
                },
                electricityAvailable: {
                    name: "Camping with electricity",
                    available: false
                },
                restaurant: {
                    name: "Restaurant",
                    available: false
                },
                kiosk: {
                    name: "Kiosk",
                    available: false
                },
                rentals: {
                    name: "Bike Rentals",
                    available: false
                },
                clinics: {
                    name: "Bike Clinic",
                    available: false
                },
                toilets: {
                    name: "Toilets",
                    available: false
                },
                showers: {
                    name: "Showers",
                    available: false
                },
                bikeWash: {
                    name: "Bike Wash",
                    available: false
                },
                lockers: {
                    name: "Lockers",
                    available: false
                },
                chairlifts: {
                    name: "Chair lift",
                    available: false
                },
                tbarlifts: {
                    name: "T-Bar lift",
                    available: false
                },
                gondolalifts: {
                    name: "Gondola lift",
                    available: false
                },
                buttonlifts: {
                    name: "Button lift",
                    available: false
                }
            },
            trails: {
                greens: 0,
                blues: 0,
                reds: 0,
                blacks: 0,
                jumplines: 0
            },
            openingHours:{
                comments: '',
                seasonOpens: '',
                seasonCloses: '',
                monday: {
                    opens: '',
                    closes: ''
                },
                thuesday: {
                    opens: '',
                    closes: ''
                },
                wednsday: {
                    opens: '',
                    closes: ''
                },
                thursday: {
                    opens: '',
                    closes: ''
                },
                friday: {
                    opens: '',
                    closes: ''
                },
                saturday: {
                    opens: '',
                    closes: ''
                },
                sunday: {
                    opens: '',
                    closes: ''
                }
            }
        }

        
        

        const handleSubmit = async () => {
            // if(bikepark.coverUrl){
            isPending.value = true
            //await uploadImage(bikepark.coverUrl.value)
            const res = await addDoc( bikepark ) 

            isPending.value = false
            if(!error.value){
                router.push({ name: 'BikeparkDetails', params: { id: res.id }})
            }
        }
        
        const allowedFileTypes  = ['image/png', 'image/jpeg']

        const handleChange = (e) => {
           
            const selectedFile = e.target.files[0]
            if(selectedFile && allowedFileTypes.includes(selectedFile.type)){
                bikepark.coverUrl.value = selectedFile
                bannerError.value = null
            }else{
                bikepark.coverUrl.value = null
                bannerError.value = 'Please upload a .png or .jpg file'
            }
        }

        return { handleSubmit, handleChange, bannerError, isPending, bikepark }
    },
    methods: {
        changeCountry (event) {
            this.bikepark.info.country = event.target.value
        }
    }
}
</script>

<style>
    .weekday{
        display: grid;
    }
    .weekday label{
        padding-right: 10;
        margin-right: 10;
        border-right: 1px solid var(--color-accent);
    }
    .single-faq{
        display: flex;
        align-items: center;
    }
</style>