import { projectStorage } from "../firebase/config";
import { v4 as uuidv4} from 'uuid'
import { ref } from 'vue'

const useStorage = () => {
    const error = ref(null)
    const url = ref(null)
    const filePath = ref(null)
    

    const uploadImage = async (file) => {
        filePath.value = `banners/${uuidv4()}`
        const storageRef = projectStorage.ref(filePath.value)

        try {
           const res = await storageRef.put(file)
           url.value = await res.ref.getDownloadURL()
         
        }catch(err) {
            error.value = err.message
        }
    }

    const deleteImage = async(path) => {
        const storageRef = projectStorage.ref(path)

        try {
            await storageRef.delete()
        } catch (error) {
            error.value = error.message
        }
    }

    return { url, filePath, error, uploadImage, deleteImage }
}

export default useStorage