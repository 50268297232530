<template>
  <nav class="bg-white dark:bg-gray-800 shadow">
        <div class="max-w-7xl mx-auto px-8">
            <div class="flex items-center justify-between h-16">
                <div class="w-full justify-between flex items-center">
                    <router-link :to="{ name: 'Home' }">BIKR</router-link>
                    <div v-if="user">
                        <AuthedNavLinks :user="user" />
                    </div>
                    <div v-else>
                        <UnauthedNavLinks />
                    </div>
                </div>
            </div>
        </div>
        <div class="md:hidden">
            <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <div v-if="user">
                    <router-link class="text-gray-800 dark:text-white block px-3 py-2 rounded-md text-base font-medium" :to="{ name: 'Bikeparks'} ">Bikeparks</router-link>
                    <router-link class="text-gray-800 dark:text-white block px-3 py-2 rounded-md text-base font-medium" :to="{ name: 'UserFavorites'} ">Favorites</router-link>
                    <router-link class="text-gray-800 dark:text-white block px-3 py-2 rounded-md text-base font-medium" :to="{ name: 'CreateBikepark'} ">Create Bikepark</router-link>
                    
                    <a class="text-gray-800 dark:text-white block px-3 py-2 rounded-md text-base font-medium" href="/#" @click="handleClick">
                        Logout
                    </a>
                </div>
                <div v-else>
                    <router-link class="text-gray-800 dark:text-white block px-3 py-2 rounded-md text-base font-medium" :to="{ name: 'Bikeparks'} ">Bikeparks</router-link>
                    <router-link class="text-gray-800 dark:text-white block px-3 py-2 rounded-md text-base font-medium" :to="{ name: 'Signup' }">Sign up</router-link>
                    <router-link class="text-gray-800 dark:text-white block px-3 py-2 rounded-md text-base font-medium" :to="{ name: 'Login' }">Login</router-link>
                </div>
            </div>
        </div>
    </nav>
</template>



<script>
import getUser from '@/composables/getUser'
import AuthedNavLinks from '@/components/AuthedNavLinks.vue'
import UnauthedNavLinks from '@/components/UnauthedNavLinks.vue'
import useLogout from '@/composables/useLogout'


export default {
    components: {
        AuthedNavLinks,
        UnauthedNavLinks
    },
    setup(){
        const { user } = getUser()
        const { logout } = useLogout()

        const handleClick = async () => {
            await logout()
            router.push({ name: "Home" })
        }

        return { user, handleClick }
    }
}
</script>

<style scoped>
  nav img {
      max-height: 60px;
  }
  .username {
      display: inline-block;
      margin-left: 16px;
      padding-left: 16px;
      border-left: 1px  solid var(--color-accent)
  }
</style>