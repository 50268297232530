<template>
  <div class="bikeparks">
    <div class="error" v-if="error">Could not fetch bikeparks</div>
    <div v-if="isPending">
      <span>Loading bikeparks!</span>
    </div>
    <div v-else>
      <section v-if="documents != null && documents.length > 0">
        <ListView :bikeparks="documents"/>
      </section>
      <span v-else>No bikeparks found. Mabye create one?</span>
    </div>
  </div>
</template>

<script>
import getCollection from '@/composables/getCollection'
import ListView from '@/components/ListView.vue'
export default {
  setup(){
    const { error, documents, isPending } = getCollection('bikeparks')
    return { error, documents, isPending }
  },
  components: {
    ListView
  }
}
</script>
