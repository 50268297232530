import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'

const useDocumentById = (collection, id) => {

  const error = ref(null)
  const isPending =  ref(false)

  let docRef = projectFirestore.collection(collection).doc(id)
  

  // update a document
  const updateDoc = async (updates) => {
    
    error.value = null
    isPending.value = true

    try {
      const res = await docRef.update(updates)
      isPending.value = false
      return res
    }
    catch(err) {
      console.log(err)
      error.value = 'could not update the document'
      isPending.value = false
    }
  }

  const setDoc = async (document) => {
    error.value = null
    isPending.value = true

    try {
      const res = await docRef.set(document)
      isPending.value = false
      return res
    } catch (error) {
      error.value = "could not set document to specified value"
      isPending.value = false     
    }
  }

  // delete a document
  const deleteDoc = async (doc) => {
    error.value = null
    isPending.value = true

    try {
      const res = await docRef.delete(doc)
      isPending.value = false

      return res
    }
    catch(err) {
      error.value = 'could not delete the document'
      isPending.value = false
    }
  }

  return { error, isPending, deleteDoc, updateDoc, setDoc }

}

export default useDocumentById