<template>
    
    <div v-if="favorites" class="user-favorites">
        <div class="sm:flex flex-wrap justify-center text-center gap-8">
            <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-4 py-4 bg-white mt-6  shadow-lg rounded-lg dark:bg-gray-800" v-for="fav in favorites.userFavs" :key="fav.bikeparkId" >
                <FavoritePark :id="fav.bikeparkId" /> 
            </div>
        </div>
    </div>
       
    <div class=" my-6 grid justify-items-center">
        <button class="px-6 py-2  transition ease-in duration-200 uppercase rounded-full hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none">
            <router-link :to="{ name: 'CreateBikepark'}">Create new Bikepark</router-link>
        </button>
    </div>
    
</template>

<script>
import getUser from '@/composables/getUser'
import FavoritePark from '@/components/FavoritePark.vue'
import getDocumentById from '@/composables/getDocumentById'

export default {
    components: {
        FavoritePark
    },
    setup(){

        const { user } = getUser()
        const { error: favError, document: favorites } = getDocumentById('favorites', user.value.uid) // Get user favorites

        return { favorites }
    }
}
</script>

<style>

</style>