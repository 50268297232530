<template>
    <div class="hidden md:block">
        <div class="ml-10 flex items-baseline space-x-4">
            <router-link class="text-gray-300  hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium" :to="{ name: 'Bikeparks'} ">Bikeparks</router-link>
            <router-link class="text-gray-300  hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium" :to="{ name: 'Signup' }">Sign up</router-link>
            <router-link class="text-gray-300  hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium" :to="{ name: 'Login' }">Login</router-link>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>