<template>
  <div class="hidden md:block">
    <div class="ml-10 flex items-baseline space-x-4">
        <router-link class="text-gray-300  hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium" :to="{ name: 'Bikeparks'} ">Bikeparks</router-link>
        <router-link class="text-gray-300  hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium" :to="{ name: 'UserFavorites'} ">Favorites</router-link>
        <router-link class="text-gray-300  hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium" :to="{ name: 'CreateBikepark'} ">Create Bikepark</router-link>
        <span class="username">Hi {{ user.displayName }}</span>
        <a class="text-gray-300  hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium" href="/#" @click="handleClick">
            Logout
        </a>
    </div>
</div>
</template>

<script>
import { useRouter } from 'vue-router'
import useLogout from '@/composables/useLogout'

export default {
    props: ['user'],
    setup(props){
        const user = props.user;
        const { logout } = useLogout()
        const router = useRouter()

        const handleClick = async () => {
            await logout()
            router.push({ name: "Home" })
        }
        return { handleClick, user }
    }
}
</script>

<style>

</style>