<template>
  About page for clampland
</template>

<script>
export default {

}
</script>

<style>

</style>