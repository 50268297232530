<template>
  
<footer class="w-full pin-b bg-white dark:bg-gray-800 mt-8 pt-4 pb-8 xl:pt-8">
    <div class="max-w-screen-lg xl:max-w-screen-xl mx-auto px-4 sm:px-6 md:px-8 text-gray-400 dark:text-gray-300">
        <ul class="text-lg font-light flex flex-wrap justify-center">
            <li class="w-1/2 md:w-1/2 lg:w-1/3">
                <div class="text-center">
                    <h2 class="text-gray-500 dark:text-gray-200 text-md uppercase  mb-4">
                        Our boys n girls
                    </h2>
                    <ul>
                        <li class="mb-4 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                            <a target="_blank" href="https://www.facebook.com/uffesrullandecykelverkstad/">
                                Uffes rullande cykelverkstad
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="w-1/2 md:w-1/3 lg:w-1/3">
                <div class="text-center">
                    <h2 class="text-gray-500 dark:text-gray-200 text-md uppercase mb-4">
                        bikr.se/clampland
                    </h2>
                    <ul>
                        <li class="mb-4 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                            <router-link :to="{ name: 'Changelog'} ">Changelog</router-link>
                        </li>
                        <li class="mb-4 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                            <a target="_blank" href="https://instagram.com/clampland">
                                Instagram
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="w-1/2 md:w-1/3 lg:w-1/3">
                <div class="text-center">
                    <h2 class="text-gray-500 dark:text-gray-200 text-md uppercase mb-4">
                        Top Bikeparks
                    </h2>
                    
                </div>
            </li>
        </ul>
        <div class="text-center font-extralight flex items-center justify-center">
            This page is created by the good folks over @clampland
        </div>
    </div>
</footer>

</template>

<script>
export default {

}
</script>

<style>
</style>